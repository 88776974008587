import { defineComponent } from '@vue/composition-api';
import { useRoute } from 'vue-router';
export default defineComponent({
  setup() {
    const route = useRoute();
    let url = "https://h5.qtemai.com/#/wxcallback?redirect=";

    if (route.query.redirect) {
      url += route.query.redirect;
    }

    window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxd985d07a397da051&redirect_uri=" + encodeURIComponent(url) + "&response_type=code&scope=snsapi_base&state=123#wechat_redirect"; //window.location.href="https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxf38642bb842ed656&redirect_uri="+encodeURIComponent(url)+"&response_type=code&scope=snsapi_base&state=123#wechat_redirect"
  }

});